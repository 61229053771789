jQuery(function($) {

    var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube-nocookie.com'], iframe[src^='//www.youtube.com'], object, embed"),
        $fluidEl = $("figure");

    $allVideos.each(function() {
        $(this).wrap('<figure class="video-wrapper"></figure>');

        jQuery(this)
            // jQuery .data does not work on object/embed elements
            .attr('data-aspectRatio', this.height / this.width)
            .removeAttr('height')
            .removeAttr('width');

    });

    jQuery(window).resize(function() {

        var newWidth = $(".video-wrapper").width();
        $allVideos.each(function() {

            var $el = jQuery(this);
            $el
                .width(newWidth)
                .height(newWidth * $el.attr('data-aspectRatio'));

        });

    }).resize();

});

(function() {
    function initMap(id, lattitude, longitude, popupText) {
        // check if id exists
        if (!document.getElementById(id)) {
            return;
        }
        
        // set up the map
        var map = new L.Map(id);

        // create the tile layer with correct attribution
        var osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        var osmAttrib = 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
        var osm = new L.TileLayer(osmUrl, {
            maxZoom: 18,
            minZoom: 10,
            attribution: osmAttrib
        });

        // start the map
        map.setView(new L.LatLng(lattitude, longitude), 16);
        map.addLayer(osm);

        // create the marker
        var customIcon = L.icon({
            iconUrl: '/images/marker-icon.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowUrl: '/images/marker-shadow.png',
            shadowSize: [41, 41]
        });

        // add the marker with the appropriate text
        var marker =L.marker([lattitude, longitude], {icon: customIcon}).addTo(map);
        marker.bindPopup(popupText).openPopup();
    }

    initMap('map', 52.51023, 9.99321, 'Grüne Allee 12<br/>31303 Burgdorf-Ramlingen');
})();

(function($) {
    var quickbook = $('.quickbook-container');    

    function targetIsQuickbook(target) {
        return target.attr('id') == 'd21-quickbook' || target.hasClass('quickbook-container');
    }
    
    quickbook.click(function(e) {        
        var _target = $(e.target);
        if (targetIsQuickbook(_target)) {
            $(this).toggleClass('open');
        }
    });

})(jQuery);

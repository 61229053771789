(function($, sr) {

    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function(func, threshold, execAsap) {
        var timeout;

        return function debounced() {
            var obj = this,
                args = arguments;

            function delayed() {
                if (!execAsap)
                    func.apply(obj, args);
                timeout = null;
            }

            if (timeout)
                clearTimeout(timeout);
            else if (execAsap)
                func.apply(obj, args);

            timeout = setTimeout(delayed, threshold || 100);
        };
    };
    // smartresize 
    jQuery.fn[sr] = function(fn) {
        return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
    };

})(jQuery, 'smartresize');

(function($) {
    function initAcc() {
        $('#menu-card .menu-type').each(function(index, element) {
            $(this).addClass('active');
            $(element).on('click', function() {
                $(this).toggleClass('active');
                var panel = $(this).next();
                
                if (panel.height() > 0) {
                    panel.css('max-height', '');
                } else {
                    panel.css('max-height', panel[0].scrollHeight + 'px');
                    $('html, body').animate({
                        scrollTop: ($(this).offset().top - $('.site-logo').height() - 10)
                    }, 1000);
                }
            });
        });


        var collapseButton = $('<button class="collapse expanded"></button>');
        collapseButton.html('Alle zuklappen');
        collapseButton.click(function(e) {
            if (e.target.hasClass('collapsed')) {
                collapseButton.html('Alle zuklappen');
                e.target.removeClass('collapsed');
                e.target.addClass('expanded');

                $('#menu-card .menu-type').each(function() {
                    $(this).addClass('active');
                    var panel = $(this).next();
                    panel.css('max-height', panel[0].scrollHeight + 'px');
                });
            } else if (e.target.hasClass('expanded')) {
                collapseButton.html('Alle aufklappen');
                e.target.removeClass('expanded');
                e.target.addClass('collapsed');

                $('#menu-card .menu-type').each(function(index, element) {
                    $(this).removeClass('active');
                    var panel = $(this).next();
                    panel.css('max-height', '');
                });
            }
            e.target.blur();
        });
        $('#menu-card').prepend(collapseButton).addClass('collapsible');
    }

    $(document).ready(function() {
        initAcc();
        // resize accordion if screen size changes
        $(window).smartresize(function() {
            $('#menu-card .menu-type').each(function(index, element) {
                if ($(element).hasClass('active')) {
                    var panel = $(element).next();
                    panel.css('max-height', panel[0].scrollHeight + 'px');
                }
            });
        });
    });

})(jQuery);

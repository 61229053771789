/*global menuLocaliseText,jQuery*/
/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
(function ($) {
    var masthead,
        menuLocaliseText,
        menuToggle,
        menuToggleText,
        siteMenu,
        siteNavigation,
        siteNavigationOverlay;

    masthead = $('#masthead');
    menuLocaliseText = {
        expand: 'Menü öffnen',
        collapse: 'Menü schließen'
    };
    menuToggle = masthead.find('.nav-toggle');
    menuToggleText = menuToggle.find('.screen-reader-text');
    siteNavigation = masthead.find('.site-navigation');
    siteMenu = masthead.find('#site-navigation .joomla-nav');

    // Add the overlay div for closing the mobile menu
    // by clicking outside of the menu (and inside the overlay).
    siteNavigationOverlay = $('<div class="site-navigation__overlay"></div>');
    $('body').append(siteNavigationOverlay);

    function initMainNavigation(container) {

        // Add dropdown toggle that displays child menu items.
        var dropdownToggle = $('<button />', { 'class': 'dropdown-toggle', 'aria-expanded': false })
            .append($('<span />', { 'class': 'dropdown-symbol', text: '+' }))
            .append($('<span />', { 'class': 'screen-reader-text', text: menuLocaliseText.expand }));

        container.find('.parent > a').after(dropdownToggle);

        container.find('.dropdown-toggle').click(function (e) {
            var _this = $(this),
                screenReaderSpan = _this.find('.screen-reader-text');
            var dropdownSymbol = _this.find('.dropdown-symbol');
            dropdownSymbol.text(dropdownSymbol.text() === '-' ? '+' : '-');

            e.preventDefault();
            _this.toggleClass('toggled-on');
            var subMenu = _this.next('.children, .sub-menu, ul');
            subMenu.toggleClass('toggled-on');

            subMenu.slideToggle('fast');

            if (_this.attr('aria-expanded') === 'false') {
                _this.attr('aria-expanded', 'true');
            }
            else {
                _this.attr('aria-expanded', 'false');
                _this.blur();
            }

            screenReaderSpan.text(screenReaderSpan.text() === menuLocaliseText.expand ? menuLocaliseText.collapse : menuLocaliseText.expand);
        });
    }

    initMainNavigation(siteNavigation);

    // Enable menuToggle.
    (function () {

        // Return early if menuToggle is missing.
        if (!menuToggle.length) {
            return;
        }

        // Add an initial values for the attribute.
        siteNavigation.attr('aria-expanded', 'false');
        siteMenu.attr('aria-expanded', 'false');

        menuToggle.on('click.mousy', function () {
            toggleMenu();

            siteNavigationOverlay.one('click', function() {
                toggleMenu();
            });

        });
    })();

    function toggleMenu() {
        siteNavigation.toggleClass('toggled-on');
        $('body').toggleClass('menu-open');

        menuToggleText.text($.trim(menuToggle.text()) === menuLocaliseText.openMenu ? menuLocaliseText.closeMenu : menuLocaliseText.openMenu);
    
        siteNavigation.attr(
            'aria-expanded',
            siteNavigation.attr('aria-expanded') === 'false' ? 'true' : 'false'
        );
        menuToggle.attr(
            'aria-expanded',
            menuToggle.attr('aria-expanded') === 'false' ? 'true' : 'false'
        );
        
        siteMenu.attr(
            'aria-expanded',
            siteMenu.attr('aria-expanded') === 'false' ? 'true' : 'false'
        );
    }

    // Fix sub-menus for touch devices and better focus for hidden submenu items for accessibility.
    (function () {
        if (!siteMenu.length || !siteMenu.children().length) {
            return;
        }

        // Toggle `focus` class to allow submenu access on tablets.
        function toggleFocusClassTouchScreen() {
            if ('none' === $('.nav-toggle').css('display')) {

                $(document.body).on('touchstart.mousy', function (e) {
                    if (!$(e.target).closest('#main-navigation li').length) {
                        $('#main-navigation li').removeClass('focus');
                    }
                });

                siteMenu.find('.menu-item-has-children > a, .page_item_has_children > a')
                    .on('touchstart.mousy', function (e) {
                        var el = $(this).parent('li');

                        if (!el.hasClass('focus')) {
                            e.preventDefault();
                            el.toggleClass('focus');
                            el.siblings('.focus').removeClass('focus');
                        }
                    });

            } else {
                siteMenu.find('.menu-item-has-children > a, .page_item_has_children > a').unbind('touchstart.mousy');
            }
        }

        if ('ontouchstart' in window) {
            $(window).on('resize.mousy', toggleFocusClassTouchScreen);
            toggleFocusClassTouchScreen();
        }

        siteMenu.find('a').on('focus.mousy blur.mousy', function () {
            $(this).parents('.menu-item, .page_item').toggleClass('focus');
        });
    })();

    // Add the default ARIA attributes for the menu toggle and the navigations.
    function onResizeARIA() {
        if ('inline-block' === $('.nav-toggle').css('display')) {

            if (siteNavigation.hasClass('toggled-on')) {
                menuToggle.attr('aria-expanded', 'true');
                siteMenu.attr('aria-expanded', 'true');
                siteNavigation.attr('aria-expanded', 'true');
                $('body').addClass('menu-open');
            } 
            else {
                menuToggle.attr('aria-expanded', 'false');
                siteMenu.attr('aria-expanded', 'false');
                siteNavigation.attr('aria-expanded', 'false');
                $('body').removeClass('menu-open');
                siteNavigationOverlay.off('click');
            }
        } 
        else {
            menuToggle.removeAttr('aria-expanded');
            siteMenu.removeAttr('aria-expanded');
            siteMenu.removeAttr('style');
            siteNavigation.removeClass('toggled-on');
            siteNavigation.removeAttr('aria-expanded');
            menuToggle.removeAttr('aria-controls');
            menuToggleText.text($.trim(menuLocaliseText.openMenu));
            $('body').removeClass('menu-open');
        }
    }

    $(document).ready(function () {
        $(window).on('load.mousy', onResizeARIA);
        $(window).on('resize.mousy', onResizeARIA);
    });

})(jQuery);

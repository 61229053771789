(function ($) {
    /*
    * Test if inline SVGs are supported.
    * @link https://github.com/Modernizr/Modernizr/
    */
    function supportsInlineSVG() {
        var div = document.createElement('div');
        div.innerHTML = '<svg/>';
        return 'http://www.w3.org/2000/svg' === ('undefined' !== typeof SVGRect && div.firstChild && div.firstChild.namespaceURI);
    }

    if (true === supportsInlineSVG()) {
        document.documentElement.className = document.documentElement.className.replace(/(\s*)no-svg(\s*)/, '$1svg$2');
    }
    
    /*
     * Replace the no-js class with a js class if this script is run and therefore Javascript is also supported.
     */
    document.documentElement.className = document.documentElement.className.replace(/(\s*)no-js(\s*)/, '$1js$2');
})(jQuery);
